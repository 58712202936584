import { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import img from '../assets/images/nossa_marca.svg'
import { getPage } from "../http/api";

const OurBrandPage = () => {

    const { t } = useTranslation()
    const [data, setData] = useState()

    useEffect(() => {
        getPage('our-brand').then(setData)
    }, [])

    return <Container className='h-100'>

        <Row className='h-100 align-content-center align-items-center justify-content-center'>

            <Col xs={12} md={4}>
                <Image src={img} fluid />
            </Col>

            <Col xs={12} md={7}>

                <h2>{t('our_brand')}</h2>
                <div>
                    <div dangerouslySetInnerHTML={{ __html: data }}  style={{textAlign: 'justify'}}/>
                </div>

            </Col>
        </Row>
    </Container>

}

export default OurBrandPage;
