const generateCsv = (input, { separator = ';', array_separator = ',', ...rest } = {}) => {

    const data = input.map((item, index) => {

        let text = '';

        if (index === 0)
            text += Object.keys(item).join(separator) + '\n'

        text += Object.values(item).map(line => JSON.stringify(Array.isArray(line) ? line.join(array_separator) : line)).join(separator)

        return text

    }).join('\n');

    return {
        get: () => data,
        download: (filename) => {
            const element = document.createElement("a");
            const file = new Blob([data], {
                type: "text/csv"
            });
            element.href = URL.createObjectURL(file);
            element.download = filename;
            document.body.appendChild(element);
            element.click();
        }
    }
};

export { generateCsv }