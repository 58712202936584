import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import './condition-picker.scss'

const ConditionPicker = ({ value, onChange }) => {

    const { t } = useTranslation()

    return <Form.Select aria-label={t('condition')} className='condition_picker' value={value} onChange={e => onChange(e.target.value)}>
        <option disabled>{t('select_option')}</option>
        <option value="OR">{t('condition_or')}</option>
        <option value="AND">{t('condition_and')}</option>
    </Form.Select>
}

export default ConditionPicker;