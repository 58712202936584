import { BrowserRouter, Route, Routes } from "react-router-dom";
import BaseLayout from "../layout/base-layout";
import ResultLayout from "../layout/result-layout";
import AboutUsPage from "../pages/about-us";
import DevelopmentPage from "../pages/development";
import FeedbackPage from "../pages/feedback";
import MashEditor from "../pages/mash-editor";
import OurBrandPage from "../pages/our-brand";
import Pages from "../pages/pages";
import ResultPage from "../pages/result-page";
import SearchPage from "../pages/search-page";

const Router = () => {

    return <BrowserRouter>
        <Routes>
            <Route path='/' element={<BaseLayout />}>
                <Route index element={<SearchPage />} />
                <Route path='editor' element={<MashEditor />} />
                <Route path='about' element={<AboutUsPage />} />
                <Route path='brand' element={<OurBrandPage />} />
                <Route path='feedback' element={<FeedbackPage />} />
                <Route path='development' element={<DevelopmentPage />} />
                <Route path='p/:page' element={<Pages />} />
            </Route>
            <Route path='/search' element={<ResultLayout />}>
                <Route index element={<ResultPage />} />
            </Route>
        </Routes>
    </BrowserRouter>

}

export default Router;