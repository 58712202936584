import { useEffect, useState } from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import img from '../assets/images/sobre_nos.svg'
import { getPage } from "../http/api";

const AboutUsPage = () => {

    const { t } = useTranslation()
    const [data, setData] = useState()

    useEffect(() => {
        getPage('about-us').then(setData)
    }, [])

    return <Container className='h-100'>

        <Row className='h-100 align-content-center align-items-center justify-content-center'>

            <Col xs={12} md={5}>
                <Image src={img} fluid />
            </Col>

            <Col xs={12} md={6}>
                <h1>{t('about_us')}</h1>
                <div>
                    <div dangerouslySetInnerHTML={{ __html: data }} style={{textAlign: 'justify'}} />
                </div>
            </Col>

        </Row>

    </Container >
}

export default AboutUsPage;
