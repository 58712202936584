import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useQuery from '../hooks/use-query';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CardGroup, Form, Button, Row, Col, Card, ListGroup, Badge, Container, OverlayTrigger, Tooltip, InputGroup } from "react-bootstrap";
import { complementQuery, wordSearch } from "../http/api";
import { each, map, filter } from "../utils/each";
import './mesh-editor.scss'
import { useTranslation } from "react-i18next";
import ConditionPicker from "../components/condition-picker";
import { useLoader } from "../loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import useStorage from "../hooks/storage";
import Swal from "sweetalert2";

const MashEditor = () => {

    const { text: query_string } = useQuery()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { showLoader, hideLoader } = useLoader()

    const [restoreData, setRestoreData] = useState(null)
    
    const store = useStorage(query_string)

    useEffect(() => {
        setRestoreData(store.get())
    }, [query_string])

    // Query string from user
    const [query, setQuery] = useState(query_string || '')

    const [complements, setComplements] = useState([])
    const [ages, setAges] = useState([])
    const [complement, setComplement] = useState('')
    const [age, setAge] = useState('')
    // Generated conditions
    const [conditions, setConditions] = useState({})
    // Available words for PICO
    const [pico, setPico] = useState({})
    const [custom, setCustom] = useState('')

    const [finalCondition, setFinalCondition] = useState('')

    useEffect(() => {

        if (!query_string)
            return navigate('/')

        showLoader()
        wordSearch(query_string)
            .then(words => setPico({ words: (words || []), p: [], i: [], c: [], o: [] }))
            .then(() => complementQuery())
            .then(complement => {
                let _complements = (complement || []).filter(a => a.category == 1);
                let _age = (complement || []).filter(a => a.category != 1);
                setComplements(_complements)
                setAges(_age)
            })
            .catch(error => {
                Swal.fire(t('error'), t('error_loading_data'), 'error').then(r => {
                    navigate('/')
                })
                return error;
            })
            .finally(() => hideLoader())


    }, [navigate, hideLoader, showLoader, query_string])

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    /**
     * Moves an item from one list to another list.
     */
    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        const cloneConditions = Array.from(conditions);

        each(({ items, ...rest }, index) => {

            cloneConditions[index] = { ...rest, items: items.filter(i => i.word !== removed.word) }

        }, cloneConditions)

        setConditions(cloneConditions)

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        // styles we need to apply on draggables
        ...draggableStyle,
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        // change background colour if dragging
        background: isDragging ? 'lightgreen' : 'transparent',
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? 'lightblue' : 'transparent'
    });

    const onDragEnd = result => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {

            let state = reorder(
                pico[source.droppableId],
                source.index,
                destination.index
            );
            setPico({ ...pico, [destination.droppableId]: state })
        } else {
            const result = move(
                pico[source.droppableId],
                pico[destination.droppableId],
                source,
                destination
            );

            setPico({ ...pico, ...result })
        }

    };

    const updateCondition = (letter, word, condition) => {

        const letterConditions = conditions[letter];

        if (word === null)
            setConditions({ ...conditions, [letter]: { ...letterConditions, condition } })
        else
            setConditions({
                ...conditions, [letter]: {
                    ...conditions[letter], items: letterConditions.items.map(item => {
                        if (item.word === word) {
                            item.condition = condition;
                        }
                        return item;
                    })
                }
            })
    }

    useEffect(() => {

        const output = { ...conditions };

        each((words, pLetter) => {
            if (pLetter === 'words') return;

            const group = output[pLetter] || { items: [], condition: 'AND' };

            each(({ word, mesh }, wIndex) => {
                if (!group.items.find(i => i.word === word))
                    group.items.push({ word, mesh, condition: 'OR' })
            }, words);

            output[pLetter] = group;
        }, pico)

        setConditions(filter((set, letter) => {
            return set.items.length > 0
        }, output))

    }, [pico])

    useEffect(() => {

        const copy = { ...conditions }
        let c = '';
        const len = Object.keys(copy).length;
        each((set, letter, index) => {
            c += '(' + set.items.map((i, n) => `${i.mesh} ${n < set.items.length - 1 ? i.condition : ''}`).join(' ') + ')';
            if (index < len - 1)
                c += ` ${set.condition} `;
        }, copy)

        if (complements[complement])
            c += ' ' + complements[complement].query;

        if (ages[age])
            c += ' ' + ages[age].query;

        setFinalCondition(c)

    }, [conditions, complement, age])

    const doSearch = () => {
        store.set({ complements, complement, conditions, pico, custom, finalCondition }).finally(() => {
            navigate(`/search?q=${finalCondition}`)
        })
    }

    const Helper = ({ children }) => {
        return <p className='text-center text-muted'>{children}</p>
    }

    const isChecked = useCallback((item) => {
        return item === complement
    }, [complement])

    const isAgeChecked = useCallback(item => {
        return item === age;
    }, [age])

    const addCustom = () => {
        const output = { ...pico, words: [...pico.words, { word: custom, mesh: custom, extra: true }] }
        setPico(output)
        setCustom('')
    }

    const removePico = (letter, index) => {
        const copy = { ...pico }
        copy[letter].splice(index, 1)
        setPico(copy)
    }

    const restorePico = () => {
        setComplements(restoreData.complements || [])
        setComplement(restoreData.complement || '')
        setConditions(restoreData.conditions || {})
        setPico(restoreData.pico || {})
        setCustom(restoreData.custom || '')
        setFinalCondition(restoreData.finalCondition || '')
    }

    return <>

        <Container className='py-2'>

            <Form method='get' action='/editor' className='mb-3'>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Control value={query} onChange={e => setQuery(e.target.value)} name='text' />
                        </Form.Group>
                    </Col>
                    <Col sm='12' md='auto'>
                        <Button type='submit'>
                            <span>{t('search')}</span>
                        </Button>
                    </Col>
                </Row>
            </Form>

            {restoreData !== null && <Helper>
                <Button onClick={e => restorePico()}>{t('restore_previous_data')}</Button>
            </Helper>}

            <Helper>{t('drag_to_generate_mash')}</Helper>

            <CardGroup>
                <DragDropContext onDragEnd={onDragEnd}>
                    {map((items, lIndex, nIndex) => <Droppable droppableId={lIndex} key={nIndex}>{(provided, snapshot) => {

                        const title = t(`pico_${lIndex}`)
                        const description = t(`pico_${lIndex}_description`)
                        const helpText = t(`pico_${lIndex}_help`)
                        const popover = helpText ? (<Tooltip id={`tooltip_${lIndex}`}>
                            {helpText}
                        </Tooltip>) : <></>;

                        return <Card bg={`pico-${lIndex}`}>
                            <Card.Header>
                                {title}{' '}
                                {description && <OverlayTrigger overlay={popover}>
                                    <Badge>
                                        <span className='d-sm-none d-md-none d-lg-inline'>{`(${description})`}</span>{' '}
                                        <FontAwesomeIcon icon={faQuestionCircle} fixedWidth />
                                    </Badge>
                                </OverlayTrigger>}
                            </Card.Header>
                            {lIndex === 'words' && <Card.Body>
                                <InputGroup>
                                    <Form.Control value={custom} onChange={e => setCustom(e.target.value)} placeholder={'Adicionar termo'} />
                                    <Button onClick={e => addCustom()} disabled={!custom}>
                                        <FontAwesomeIcon icon={faPlus} fixedWidth />
                                    </Button>
                                </InputGroup>
                            </Card.Body>}
                            <ListGroup style={getListStyle(snapshot.isDraggingOver)}
                                ref={provided.innerRef}
                                className="list-group-flush h-100"
                            >
                                {items.map((item, index) => (
                                    <Draggable
                                        key={`${item.word}`}
                                        draggableId={item.word}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <ListGroup.Item as={Badge} style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className='bg-secondary text-light m-1'
                                            >
                                                {item.word}
                                                {item.extra && <Button variant='link' size="sm" title={t('remove')} onClick={e => removePico(lIndex, index)}>
                                                    <FontAwesomeIcon icon={faMinus} fixedWidth />
                                                </Button>}
                                            </ListGroup.Item>
                                        )}
                                    </Draggable>
                                ))}

                                {provided.placeholder}
                            </ListGroup>
                        </Card>
                    }}
                    </Droppable>, pico)}
                </DragDropContext>
            </CardGroup>


            {(Object.keys(conditions).length > 1 ||
                Object.entries(conditions).map(([letter, { items }]) => items.length > 1).some(i => i)) &&
                <div className='my-3'>

                    <h5>Personalizar condições</h5>

                    <Form className='d-flex align-items-center justify-content-center'>
                        {conditions &&
                            map((set, letter, pIndex) => {

                                return <>
                                    {!!set.items.length && <>
                                        {pIndex > 0 && pIndex < 4 && <ConditionPicker value={set.condition} onChange={value => updateCondition(letter, null, value)} />}
                                        <span className='mx-2'>{' ( '}</span>
                                        {map(({ word, condition, ...rest }, index) => {
                                            return <>
                                                <Badge className='m-2' bg={`pico-${letter}`}>{word}</Badge>
                                                {set.items.length > 1 && index < set.items.length - 1 && <ConditionPicker value={condition} onChange={value => updateCondition(letter, word, value)} />}
                                            </>
                                        }, set.items)}
                                        <span className='mx-2'>{' ) '}</span>
                                    </>}
                                </>
                            }, conditions)}
                    </Form>
                </div>}

            <div className='py-3'>
                <h5>{t('search_strategy')}</h5>
                <Form.Control as={'textarea'} rows={2} multiple value={`${finalCondition}`} readOnly />
            </div>

            <div className="row">
                {complements && <div className="col-sm-12 col-md-6">
                    <h5>{t('query_complement')}</h5>
                    {complements.map((item, index) => {
                        return <Form.Check key={`check_${index}`} type='radio' name='category' label={item.title} checked={isChecked(index)} onChange={e => setComplement(e.target.checked ? index : -1)} />
                    })}
                </div>}

                {ages && <div className="col-sm-12 col-md-6">
                    <h5>{t('group_age')}</h5>
                    {ages.map((item, index) => {
                        return <Form.Check key={`check_${index}`} type='radio' name='ages' label={item.title} checked={isAgeChecked(index)} onChange={e => setAge(e.target.checked ? index : -1)} />
                    })}
                </div>}
            </div>

            <div className='text-center'>
                <Button type="submit" disabled={!finalCondition} onClick={e => doSearch()}>{t('search_articles')}</Button>
            </div>

        </Container>
    </>
}

export default MashEditor;
