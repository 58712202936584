import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Card, Table, Badge, Form, Button, ButtonGroup, Image, Alert, Modal } from "react-bootstrap";
import { faChevronCircleLeft, faEnvelope, faFileCsv, faFilter, faInfoCircle, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from "react-select";
import useQuery from "../hooks/use-query";
import { meshQuery, sendList } from "../http/api";
import ExpandCollapse from 'react-expand-collapse';
import { useTranslation } from 'react-i18next';

import './result-page.scss'
import noResults from '../assets/images/undraw_web_search_re_efla.svg'
import Header from "../components/header";
import { generateCsv } from "../utils/generate-csv";
import { useLoader } from "../loader";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const ResultPage = () => {

    const { q: queryInput, category } = useQuery();
    const { t } = useTranslation()
    const { showLoader, hideLoader, loading } = useLoader()
    const navigate = useNavigate()

    const [loaded, setLoaded] = useState(false)
    const [filters, setFilters] = useState({})
    const [selectedFilters, setSelectedFilters] = useState([])
    const [results, setResults] = useState([])
    const [filteredResults, setFilteredResults] = useState([])
    const [selectedResults, setSelectedResults] = useState([])
    const [email, setEmail] = useState('')
    const [isModalOpen, toggleModal] = useState(false)
    const [isUsingTranslation, toggleUsingTranslation] = useState(false)

    useEffect(() => {
        showLoader()
        meshQuery(queryInput, category)
            .then(({ filters, results }) => {
                setFilters(filters)
                setResults(results)
            }).catch(error => {
                Swal.fire(t('error'), t('error_loading_data'), 'error').then(r => {
                    navigate('/')
                })
                return error;
            }).finally(() => {
                setLoaded(true)
                hideLoader()
            })
    }, [queryInput, category, hideLoader, showLoader])

    const selectFilters = (key, selected) => {
        const filter = { ...selectedFilters, [key]: selected };
        Object.entries(filter).forEach(([key, value]) => {
            if (value && value.length === 0)
                delete filter[key]
        })
        setSelectedFilters(filter);
    }

    const toggleFilter = (key, value) => {
        const input = { ...selectedFilters }
        const filter = input[key] || [];

        const index = filter.indexOf(value)
        if (index < 0)
            filter.push(value)
        else
            filter.splice(index, 1)

        if (filter.length === 0) {
            delete input[key]
            setSelectedFilters(input)
        }
        else
            setSelectedFilters({ ...input, [key]: filter });
    }

    useEffect(() => {

        const sFilters = Object.entries(selectedFilters)
        const cloneResults = Array.from(results)
        let output = []

        if (sFilters.length === 0 || sFilters.map(([letter, items]) => items.length > 1).some(i => i))
            output = cloneResults;
        else
            output = cloneResults.filter(article => {
                return sFilters.some(([key, selectedOptionsInput]) => {
                    let currentFilter = article[key] || [];
                    const selectedOptions = selectedOptionsInput.map((input) => input.value || input)

                    if (!Array.isArray(currentFilter))
                        currentFilter = [currentFilter]

                    return currentFilter.some(r => selectedOptions.includes(r));
                });
            })

        setFilteredResults(output)

    }, [selectedFilters, results])

    const generateLink = (pubmed_id, type = 'pubmed') => {
        switch (type) {
            case 'doi':
                return `https://doi.org/${pubmed_id}`
            default:
                return `https://pubmed.ncbi.nlm.nih.gov/${pubmed_id}/`
        }
    }

    const renderFilters = ({ key, value }) => {

        const isClearable = value.length > 1;
        const selectedFilter = selectedFilters[key] || []

        value.sort()

        switch (key) {
            case 'publication_date':

                return <Form.Group>
                    {value.map((v, i) => {
                        return <Form.Check key={`pd_${i}_${key}`} checked={value.length === 1 || selectedFilter.includes(v)} disabled={value.length === 1} label={v} onChange={e => toggleFilter(key, v)} />
                    })}
                </Form.Group>

            default:
                const options = value.map(option => ({ label: option, value: option, isFixed: !isClearable }))
                const defaultOptions = isClearable ? [] : [options[0]]

                return <Select isMulti closeMenuOnSelect={false} options={options} onChange={selected => selectFilters(key, selected)} isClearable={isClearable} styles={{
                    multiValueRemove: (base, state) => {
                        return state.data.isFixed ? { ...base, display: 'none' } : base
                    }
                }} defaultValue={defaultOptions} isDisabled={!isClearable} placeholder={t('select_option')} />;
        }
    }

    const selectResult = useCallback((id) => {
        const clonedResults = Array.from(selectedResults)

        const index = clonedResults.indexOf(id)

        if (index < 0)
            clonedResults.push(id)
        else
            clonedResults.splice(index, 1)

        setSelectedResults(clonedResults)

    }, [selectedResults])

    const selectAllResults = (selectAll) => {
        let selected = []

        if (selectAll)
            selected = filteredResults.map(item => item.article_id);

        setSelectedResults(selected)
    }

    const isResultSelected = useCallback((id) => {
        return selectedResults.includes(id)
    }, [selectedResults])

    const downloadCsv = () => {
        generateCsv(selectedResults.map(s => filteredResults.find(a => a.article_id === s))).download(`eHelpMed - ${t('search_results_file')}.csv`)
    }

    const sendMail = async () => {
        toggleModalVisibility()
        showLoader()
        const file = generateCsv(selectedResults.map(s => filteredResults.find(a => a.article_id === s))).get()
        sendList(file, email).then(e => {
            Swal.fire(
                t('success'),
                t('article_list_sent'),
                'success'
            )
        }).catch(e => toggleModalVisibility())
            .finally(e => hideLoader())
    }

    const toggleModalVisibility = () => {
        toggleModal(!isModalOpen)
    }

    return <>

        <Modal show={isModalOpen} onHide={() => toggleModalVisibility()}>
            <Modal.Header closeButton>
                <Modal.Title>{t('send_list_by_email')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{t('type_email_hint')}</p>
                {!loading && <Form.Control value={email} onChange={e => setEmail(e.target.value)} />}
                {loading && <p>{t('sending_email')}</p>}
            </Modal.Body>
            {!loading && <Modal.Footer>
                <Button variant='secondary' onClick={e => toggleModalVisibility()}>{t('cancel')}</Button>
                <Button variant='primary' onClick={e => sendMail()} disabled={!email}>{t('send')}</Button>
            </Modal.Footer>}
        </Modal>

        <Row className='layout-row'>
            {results.length > 0 && <><Col sm='12' md='4' lg='2' className='layout-col bg-primary px-3 py-3'>

                <h6 className='text-light'>
                    <FontAwesomeIcon icon={faFilter} fixedWidth />
                    <span style={{ wordWrap: 'break-word' }}>{t('filter_results')}</span>
                </h6>

                {Object.entries(filters).map(([key, value], fIndex) => {
                    return <div key={fIndex} className='mb-3'>
                        <h6 className='text-light p-2'>{t(`filter_${key}`)}</h6>
                        <Card body>
                            {renderFilters({ key, value })}
                        </Card>
                    </div>
                })}
            </Col>
            </>}
            <Col sm='12' md={results.length > 0 ? 8 : 12} lg={results.length > 0 ? 10 : 12} className='layout-col p-0'>

                <Header />

                {loaded && results.length === 0 && <Row className='h-100 align-content-center justify-content-center'>
                    <Col xs={12} md={6} lg={4} className='text-center'>
                        <Image src={noResults} fluid className='py-3' />
                        <h5>{t('no_results_found')}</h5>
                        <Button variant='link' onClick={() => window.history.back()}>
                            <FontAwesomeIcon icon={faChevronCircleLeft} fixedWidth />
                            {t('go_back_page')}
                        </Button>
                    </Col>
                </Row>}

                {results.length > 0 && <div className='result-area'>

                    <div className='p-3'>
                        <h3 style={{ wordWrap: 'break-word' }}>{t('search_results')}</h3>
                        {filteredResults.length > 0 && <h5>{t('result_counter', { count: filteredResults.length })}{' '}</h5>}
                        <Button variant='link' onClick={() => window.history.back()}>
                            <FontAwesomeIcon icon={faChevronCircleLeft} fixedWidth />
                            {t('edit_search_strategy')}
                        </Button>
                        <Button href={`https://pubmed.ncbi.nlm.nih.gov/?term=${queryInput}`} variant='link' target='_blank' rel='external noreferer' className='pull-right'>{t('view_results_on_pubmed')}</Button>
                    </div>

                    {results.length > 0 && <Row className='align-items-center py-2'>
                        <Col xs={6} className='align-items-center justify-content-start'>
                            <Form.Check label={t('select_all')} disabled={filteredResults.length === 0} checked={filteredResults.length > 0 && selectedResults.length === filteredResults.length} onChange={e => selectAllResults(e.target.checked)} />
                            <Form.Check label={t('view_translations')} type="switch" checked={isUsingTranslation} onChange={e => toggleUsingTranslation(!isUsingTranslation)} />
                        </Col>
                        <Col xs={6} className='d-flex align-items-center justify-content-end'>
                            <span className='mx-2 text-muted'>{t('export_results')}</span>
                            <ButtonGroup>
                                <Button variant='link' disabled={!selectedResults.length} onClick={e => downloadCsv()}>
                                    <FontAwesomeIcon icon={faFileCsv} fixedWidth />
                                </Button>
                                <Button variant='link' disabled={!selectedResults.length} onClick={e => toggleModalVisibility()}>
                                    <FontAwesomeIcon icon={faEnvelope} fixedWidth />
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>}

                    {filteredResults.length === 0 && <Alert variant="warning">
                        <FontAwesomeIcon icon={faInfoCircle} fixedWidth />
                        {t('selected_filters_no_result')}
                    </Alert>}

                    {filteredResults.map(({ article_id, title, title_translation, abstract, abstract_translation, magazine, authors, publication_date, pubmed_id, keywords, conclusions_translations, doi, copyright, method, conclusions, ...article }, article_index) => {

                        const pubmed_ids = pubmed_id.split('\n')
                        const link = pubmed_ids[0];

                        return <Card key={article_index} className="mb-2">
                            <Card.Body>
                                <Card.Title>
                                    <Form.Check className='d-inline' checked={isResultSelected(article_id)} onChange={e => selectResult(article_id)} />{' '}
                                    <a href={generateLink(link)} target='_blank' className='link-primary d-inline' rel='external noreferrer'>{isUsingTranslation ? title_translation : title}</a>
                                </Card.Title>
                                <Card.Subtitle>
                                    {magazine} - {publication_date}
                                </Card.Subtitle>
                                {abstract &&
                                    <ExpandCollapse
                                        previewHeight="88px"
                                        expandText={t('view_more')}
                                        collapseText={t('close')}
                                        className='card-text'
                                    >
                                        {isUsingTranslation ? abstract_translation : abstract}
                                    </ExpandCollapse>}
                            </Card.Body>

                            <Card.Footer>
                                <Table hover size='sm' responsive borderless>
                                    <tbody>
                                        <tr>
                                            <th width='150px'>{t('author')}</th>
                                            <td>{authors.map((author, index) => <Badge key={index} className='m-1' bg='secondary'>{author}</Badge>)}</td>
                                        </tr>
                                        {keywords.length > 0 && <tr>
                                            <th>{t('keywords')}</th>
                                            <td>{keywords.map((keyword, index) => <Badge key={`${index}`} className='m-1' bg='secondary'>{index} {keyword}</Badge>)}</td>
                                        </tr>}
                                        {doi && <tr>
                                            <th>{t('doi')}</th>
                                            <td><a href={generateLink(doi.split('\n')[0], 'doi')} target='_blank' rel='external noreferrer' className='link-primary'>
                                                <FontAwesomeIcon icon={faLink} fixedWidth />{' '}
                                                {doi.split('\n')[0]}</a></td>
                                        </tr>}
                                        {method && <tr>
                                            <th>{t('method')}</th>
                                            <td>{method}</td>
                                        </tr>}
                                        {conclusions && <tr>
                                            <th>{t('conclusion')}</th>
                                            <td>{isUsingTranslation ? conclusions_translations : conclusions}</td>
                                        </tr>}
                                    </tbody>
                                </Table>
                            </Card.Footer>
                        </Card>

                    })}
                </div>}

            </Col>
        </Row>
    </>
}

export default ResultPage;