const map = (callback, input) => {
    if (typeof input === 'undefined' || input === null)
        return [];
    if (Array.isArray(input)) {
        return input.map((v, i) => callback(v, i, i))
    }
    return Object.entries(input).map(([index, value], nIndex) => callback(value, index, nIndex))
}

const filter = (callback, input) => {
    if (Array.isArray(input))
        return input.filter((v, i) => callback(v, i, i))
    return Object.entries(input).filter(([index, value], nIndex) => callback(value, index, nIndex)).reduce((prev, [index, value], nIndex) => ({ ...prev, [index]: value }), {})
}

const each = (callback, input) => {
    map(callback, input)
}

export { map, each, filter };