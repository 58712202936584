import axios from './axios'

const wordSearch = (text) => {
    return axios.get('/api/word_search', { params: { text } }).then(item => item.data)
}

const meshQuery = (text, category) => {
    return axios.get('/api/query', { params: { text, category } }).then(item => item.data)
}

const complementQuery = () => {
    return axios.get('/api/query_complement').then(item => item.data)
}

const sendList = (list, email) => {
    return axios.post('/api/send-list', list, {
        params: {
            email
        },
        headers: {
            'Content-Type': 'text/csv'
        }
    })
}

const pagesList = () => {
    return axios.get('/api/p').then(item => item.data)
}

const getPage = (page) => {
    return axios.get('/api/p/' + page).then(item => item.data)
}

export { wordSearch, meshQuery, complementQuery, sendList, pagesList, getPage }