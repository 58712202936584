import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom"
import Footer from '../components/footer'

const ResultLayout = () => {
    return <Container fluid className='d-flex flex-column'>
        <Outlet className='d-flex-grow'/>
        <Footer/>
    </Container>
}

export default ResultLayout;