import { faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Footer = () => {

    const { t } = useTranslation()

    return <footer className='footer'>
        <Container className='py-3'>
            <Row>
                <Col xs={3}>
                    <span>&copy; eHelpMed - {new Date().getFullYear()}.</span>
                </Col>
                <Col className='text-right'>
                    <Button variant='link' href={t('twitter_link')} target='_blank' rel='external noreferer'>
                        <FontAwesomeIcon icon={faTwitter} fixedWidth />{' '}
                        {t('twitter_handler')}
                    </Button>
                    <Button variant='link' href={t('instagram_link')} target='_blank' rel='external noreferer'>
                        <FontAwesomeIcon icon={faInstagram} fixedWidth />{' '}
                        {t('insta_handler')}
                    </Button>
                    <Button variant='link' href={t('linkedin_link')} target='_blank' rel='external noreferer'>
                        <FontAwesomeIcon icon={faLinkedin} fixedWidth />
                    </Button>
                    <Button variant='link' href={`mailto:${t('email')}`} target='_blank' rel='external noreferer'>
                        <FontAwesomeIcon icon={faEnvelope} fixedWidth />
                    </Button>
                </Col>
            </Row>
        </Container>
    </footer>
}

export default memo(Footer);