import React, { useCallback, useState } from "react"
import { Spinner } from "react-bootstrap"
import "./loader.scss"

const LoadingContext = React.createContext({
    loading: false,
    message: null,
    showLoader: (message) => { },
    hideLoader: () => { }
})

const Loader = ({ children, ...rest }) => {

    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')

    const showLoader = useCallback((_message = 'Carregando...') => {
        setMessage(_message)
        setLoading(true)
    }, [])

    const hideLoader = useCallback(() => {
        setLoading(false)
    }, [])

    const value = {
        showLoader,
        hideLoader
    }

    return <LoadingContext.Provider value={value}>
        <LoadingContext.Consumer>
            {() => <>
                {children}
                {loading && <div className="loader">
                    <div className="loader-bg"></div>
                    <div className="loader-message text-light">
                        <Spinner animation="border">
                            <span className="visually-hidden">{message}</span>
                        </Spinner>
                        {message}
                    </div>
                </div>}
            </>}
        </LoadingContext.Consumer>
    </LoadingContext.Provider>
}

const useLoader = () => {
    return React.useContext(LoadingContext)
}

export default Loader

export { useLoader }