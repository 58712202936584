import {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {getPage} from "../http/api";
import {useParams} from "react-router-dom";


const Pages = () => {

    const {t} = useTranslation()
    const [data, setData] = useState()
    const {page} = useParams()

    useEffect(() => {
        getPage(page).then(setData)
    }, [])

    return <Container className='h-100'>

        <Row className='h-100 align-content-center align-items-center justify-content-center'>

            <Col xs={12}>
                <div dangerouslySetInnerHTML={{__html: data}} style={{textAlign: 'justify'}}/>
            </Col>

        </Row>

    </Container>
}

export default Pages;
