import React, { useCallback } from 'react'
import { Button } from 'react-bootstrap';
import { Outlet, useLocation } from "react-router-dom"
import Footer from '../components/footer'
import Header from '../components/header';
import './base-layout.scss';

const BaseLayout = () => {


  const { pathname } = useLocation();

  const isHome = useCallback(() => {
    return pathname === '/'
  }, [pathname])

  return (<div className="holder container-fluid px-0 mx-0">
    <Header />
    <main className='content'>
      <Outlet />
    </main>
    {!isHome() && <div className='text-center p-2'>
      <Button href='/'>Voltar a página inicial</Button>
    </div>}
    <Footer />
  </div>);
}

export default BaseLayout;
