import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Form, Col, Row, Button, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { logoM } from '../assets';
import useQuery from '../hooks/use-query';
import './search-page.scss';

const SearchPage = () => {

    const { q } = useQuery()
    const [query, setQuery] = useState(q)

    const { t } = useTranslation()
    const navigate = useNavigate()

    const doSearch = (e) => {
        if (!query) e.preventDefault();
        else
            navigate(`/editor?text=${query}`)
    };

    return <>

        <Form onSubmit={doSearch} className='h-100' action='/editor' method='get'>
            <Row className='align-content-center align-items-center justify-items-center justify-content-center h-100 m-0'>
                <Col xs='12' md='2' lg='1' className='text-center'>
                    <Image src={logoM} fluid />
                </Col>
                <Col xs='12' md='8' lg='6'>
                    <Form.Group>
                        <Form.Control as="textarea" rows='4' name='q' placeholder={t('search_term')} defaultValue={query} onChange={e => setQuery(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col xs='12' md='12' lg='2' className='text-center text-lg-start m-1 m-md-0'>
                    <Button type='submit'>
                        <FontAwesomeIcon icon={faSearch} fixedWidth />
                        <span>{t('search')}</span>
                    </Button>
                </Col>
            </Row>
        </Form>

    </>;
};

export default SearchPage;