import axios from 'axios'
import Swal from 'sweetalert2';

const instance = axios.create({
    // baseURL: 'https://josilainecezar.pythonanywhere.com/'
});

const showError = error => {
    Swal.fire(
        'Erro',
        error?.message || 'Um erro ocorreu. Por favor, tente novamente!',
        'error'
    )
    console.log('interceptor rodou')
    return Promise.reject(error)
}

instance.interceptors.request.use(config => config, showError);

instance.interceptors.request.use(value => value, showError)

export default instance;