const useStorage = (key, startValue = null) => {

    return {
        isEmpty() {
            return !!this.get()
        },
        get(defaultValue = null) {
            return JSON.parse(localStorage.getItem(key)) || defaultValue || startValue
        },
        set(value) {
            return new Promise(resolve => {
                localStorage.setItem(key, JSON.stringify(value))
                resolve(value)
            })
        }
    }

}

const useObjectStorage = (name, keys) => {
    const output = {};
    const store = useStorage(name)
    keys.map(key => {
        Object.defineProperty(output, key, {
            get() {
                return store.get()[key]
            },
            set(value) {
                store.set({ ...store.get(), [key]: value })
            },
            configurable: false
        })
    })
    return output;
}

export { useObjectStorage }

export default useStorage;