import {useEffect, useState} from "react"
import {Col, Container, Image, Row} from "react-bootstrap"
import {useTranslation} from "react-i18next"

import img from '../assets/images/desenvolvimento.svg'
import {getPage} from "../http/api"

const DevelopmentPage = () => {

    const {t} = useTranslation()
    const [data, setData] = useState()

    useEffect(() => {
        getPage('development').then(setData)
    }, [])

    return <Container className='h-100'>

        <Row className='h-100 align-content-center align-items-center justify-content-center'>

            <Col xs={12} md={5}>
                <Image src={img} fluid/>
            </Col>

            <Col xs={12} md={6}>

                <h2>{t('development')}</h2>

                <div>
                    <div dangerouslySetInnerHTML={{__html: data}} style={{textAlign: 'justify'}}/>
                </div>

            </Col>
        </Row>

    </Container>

}

export default DevelopmentPage;
