import { logo2 } from '../assets/index'
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Container, Navbar, Nav, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { pagesList } from '../http/api';

const Header = () => {

    const [pages, setPages] = useState([])
    const { t } = useTranslation()

    useEffect(() => {
        pagesList().then(setPages)
    }, [])

    return <nav>
        <Navbar bg="secondary" className="bg-opacity-25 pt-3 pb-3" expand="lg">
            <Container >
                <Navbar.Brand as={Link} to='/'>
                    <Image src={logo2} height={'50px'} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Link to={'/about'} className='nav-link'>{t('about_us')}</Link>
                        <Link to={'/brand'} className='nav-link'>{t('our_brand')}</Link>
                        <Link to={'/feedback'} className='nav-link'>{t('feedback')}</Link>
                        <Link to={'/development'} className='nav-link'>{t('development')}</Link>
                        {pages && pages.filter(p => !['about-us', 'our-brand', 'feedback', 'development'].includes(p)).map((page, i) => <Link key={i} to={'/p/' + page} className='nav-link'>{t('page_about_us')}</Link>)}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </nav>
}

export default Header;