import { t } from "i18next";
import { useEffect, useState } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import img from '../assets/images/undraw_contact_us_re_4qqt.svg'
import { getPage } from "../http/api";
const FeedbackPage = () => {

    const { t } = useTranslation()
    const [data, setData] = useState()

    useEffect(() => {
        getPage('feedback').then(setData)
    }, [])

    return <Container className='h-100'>

        <Row className='h-100 align-content-center align-items-center justify-content-center'>

            <Col xs={12} md={5}>
                <Image src={img} fluid />
            </Col>

            <Col xs={12} md={6}>

                <h2>{t('feedback')}</h2>

                <div>
                    <div dangerouslySetInnerHTML={{ __html: data }} style={{textAlign: 'justify'}} />
                </div>

                <Button href='https://forms.gle/dyGz7zDML4teVXeh7' target='_blank' rel='noopener'>{t('answer_form')}</Button>
            </Col>
        </Row>

    </Container>

}

export default FeedbackPage;
